import restaurant from "./images/acropole.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/1n.jpeg";
import imgOmSuflet from "./images/11.jpg";
import imgheader from "./images/header.webp";
import img2 from "./images/1.jpeg";
import imgheadermiini from "./images/3.jpeg";
import imgheadermiinimb from "./images/3.jpeg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "Ramona",
        mireasa: "Dragoș",
        data: "23 Iulie 2022",
        data_confirmare: "1 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "n.ramona.e@gmail.com", 
       tel: "+40 755 901 091",
       phone: "tel:+40755901091",
       viber: "viber://chat?number=%2B40755901091",
       whatsapp: "https://wa.me/+40755901091",
       messenger: "https://www.messenger.com/t/Dragos.Rogojinaru",
       tel1: "+40 752 914 557",
       phone1: "tel:+40752914557",
       viber1: "viber://chat?number=%2B40752914557",
       whatsapp1: "https://wa.me/+40752914557",
       messenger1: "https://www.messenger.com/t/Dragos.Rogojinaru",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă",
            localul: "Biserica",
            name: "Sfânta Vineri",
            data: "23 iulie 2022, sâmbătă, ora 14:30",
            adress: "Strada Sfânta Vineri 44, Pitești 110024, România",
            harta: "https://goo.gl/maps/vmY1Vm9jfJ9xehvh6",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.160612429133!2d24.878533915537318!3d44.85902497909842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b2bc881909f05f%3A0x2464b8446cdfde3d!2sBiserica%20Sf%C3%A2nta%20Vineri!5e0!3m2!1sro!2s!4v1651305860898!5m2!1sro!2s"
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Acropole Events Pitești, Cort Athena",
            data: "23 iulie 2022, sâmbătă, ora 19:30",
            adress: "DN65B Lângă General Electric, 117141, România",
            harta: "https://goo.gl/maps/6exd1UdTVtzM7CMi7",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.192723258335!2d24.87527551553586!3d44.81763827909864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b2a3e86092ef25%3A0xe449184984c4ace7!2sAcropole%20Events%20Pitesti!5e0!3m2!1sro!2s!4v1651305778790!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Modest & Viorica Nichitean",
            parintii_nume2: "Iliuta & Maria Rogojinaru",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Iulia & Paul",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;